import React, { useContext, useEffect, useState } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { readExcelFile, textEditor } from '../../utils'
import Header from '../../Components/Commons/Header'
import MenuLatel from '../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../utils/Compartilhados'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'

import { Toolbar } from 'primereact/toolbar'
import { filtroUsuario, tipoUsuario } from '../../utils/data'
import { Dropdown } from 'primereact/dropdown'
import { AuthContext } from '../../contexts/LegalInsights/auth'
import { Dialog } from 'primereact/dialog'
import FileUpload from '../../Components/Commons/Upload'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox';
import './index.css'
import { ButtonLabelComponent } from '../../Components/Commons/Button'
import useForm from '../../hooks/useForm'
import { showToast } from '../../utils/Compartilhados/CustomToast'

import file from '../../assets/Arquivos/Modelo - Controle de acesso.xlsx'


const Usuario = () => {
    const { showMenuConfiguracoes, showMeuPerfil, criarUsuario, consultarProjetosRLANET, projetosRLANET, arquivoUpload, criarUsuarioEmLote } = useContext(LegalInsightsCrudContext) 
    const { user, getAllUsers, usuarios , ativarUser, removeUser} = useContext(AuthContext)

    const [showModalNovoUsuario, setShowModalNovoUsuario] = useState(false)
    const [showModalCadastroEmLote, setShowModalCadastroEmLote] = useState(false)
    const [showModalCadastroUnico, setshowModalCadastroUnico] = useState(false)
    const [_usuarios, _setUsuarios] = useState([])
    const [itemSelecionado, setItemSelecionado] = useState()
    const [_projetosRLANET, _setProjetoRLANET] = useState([])

    const [usuarioAtivo, setUsuarioAtivo ] = useState(1)
    const [form, handleInputChange, clear] = useForm({
        nomeCompleto: '',
        email: '',
        telefone: '',
        celular: '',
        cnpj: '',
        nomeCliente: '',
        projeto: [],
        tipoUsuario: '',
        acessoModuloSeguros: false,
        acessoModuloProjetoAcordo: false,
        usuarioAprovador: false,
        acessoModuloGestaoDados: false,
        status: 1
      }
    )

    useEffect(()=>{
      getAllUsers('Ativo')
      consultarProjetosRLANET()
    },[])

    useEffect(()=>{
      _setUsuarios(usuarios)
    },[usuarios])

    useEffect(()=>{
      getAllUsers(form.status === 1? "Ativo" : "Inativo")
      setUsuarioAtivo(form.status === 1? 1 : 0)
    },[form.status])

    useEffect(()=>{
      _setProjetoRLANET(projetosRLANET)
    },[projetosRLANET])

    const rightToolbarTemplate = () => {
        return (
          <React.Fragment>          
            <ButtonLabelComponent label={'Novo'} icon="pi pi-plus" onClick={onClickToobarRight} />                                    
            {usuarioAtivo === 1 && <ButtonLabelComponent label="Inativar" icon="pi pi-trash" style={{border:'none'}}  onClick={() => ativarOuInativarUsuario("Inativar")} info={'info'}/>}
            {usuarioAtivo === 0 && <ButtonLabelComponent label="Ativar" icon="pi pi-check"  onClick={() => ativarOuInativarUsuario("Ativar")} info={'alert'} />}
          </React.Fragment>
        )
    }

    const ativarOuInativarUsuario = async (statusUsuario) => {
        const _ids = []
        if(statusUsuario === 'Ativar'){          
          if(itemSelecionado?.length > 0){
            itemSelecionado.map(item => _ids.push(item.uid))
            await ativarUser(_ids)
            setItemSelecionado()
          }else{
            return showToast('info','É necessário selecionar um usuário!')
          }
        }

        if(statusUsuario === 'Inativar'){
          if(itemSelecionado?.length > 0){
            itemSelecionado.map(item => _ids.push(item.uid))
            await removeUser(_ids)
            setItemSelecionado()
          }else{
            return showToast('info','É necessário selecionar um usuário!')
          }          
        }
    }

    const leftToolbarTemplate = () => {
        return (
          <React.Fragment>
            <span className='page-title'>Filtro Status: </span>
            <Dropdown value={form.status} options={filtroUsuario} name='status' placeholder="Status" onChange={handleInputChange} id={form.status}/>            
          </React.Fragment>
        )
    }

    const onHideNovoUsuario = () => {
      setShowModalNovoUsuario(!showModalNovoUsuario)
    }

    const onClickToobarRight = () => {
      setShowModalNovoUsuario(!showModalNovoUsuario)
    }

    const onClickNovoUsuarioEmLote = () => {
      setShowModalCadastroEmLote(!showModalCadastroEmLote)
    }

    const onClickNovoUsuarioUnico = () => {
      setshowModalCadastroUnico(!showModalCadastroUnico)
    }

    const onHideNovoUsuarioEmLote = () => {
      setShowModalCadastroEmLote(!showModalCadastroEmLote)
    }

    const onHideNovoUsuarioUnico = () => {
      setshowModalCadastroUnico(!showModalCadastroUnico)
    }


    const showModalCadastroNovoUsuario = () => {
        return(<Dialog
          visible={showModalNovoUsuario}
          style={{ width: '450px' }}
          header="Cadastro de Usuário"
          modal
          className='show-modal-cadastro-novo-usuario'
          onHide={onHideNovoUsuario}
          footer={
            <React.Fragment>
              <div className="dialog-show-modal-cadastro-novo-usuario footer">
                <ButtonLabelComponent label={'Em lote'} onClick={onClickNovoUsuarioEmLote} />
                <ButtonLabelComponent label={`Unitário`} onClick={onClickNovoUsuarioUnico} /> 
              </div>  
            </React.Fragment>                  
          }
        >
            <div className="dialog-show-modal-Cadastro-novo-usuario p-dialog-content">
              Como deseja realizar o cadastro?
            </div>          
        </Dialog>)
    }

    const salvarNovoUsuario = async () => {
      const response = await criarUsuario(form)
      
      if(response?.status === 200){
        clear()
        onHideNovoUsuarioUnico()
        onHideNovoUsuario()
      }
    }

    const lerArquivoExcel = async () => {
        if(arquivoUpload){
          const json = await readExcelFile(arquivoUpload, "")
          const _usuarios = []
          if(json.length > 0){
            json.map( item => {
                _usuarios.push({
                  userType: item["Tipo de Usuário"],
                  nomeCliente: item["Nome do Cliente"],
                  cnpj: item["CNPJ"],
                  name: item["Nome Completo do Usuário"],
                  email: item["E-mail do Usuário"],
                  observacoes: item["Observações"]? item["Observações"] : "",
                  avatarUrl: "",
                  perfil:{
                      acessoMenuStatusReports: String(item["Status Report na Legal Insights"]).toLocaleLowerCase() === "sim"? true : false,
                      usuarioAprovador: String(item["Perfil aprovador?"]).toLocaleLowerCase() === "sim"? true : false,
                      acessoModuloSeguros: String(item["Seguros"]).toLocaleLowerCase() === "sim"? true : false,
                      acessoModuloProjetoAcordo: String(item["Projeto Acordos"]).toLocaleLowerCase() === "sim"? true : false,
                      acessoModuloPainelGestao: String(item["Painéis de Gestão Automatizados"]).toLocaleLowerCase() === "sim"? true : false,
                  },
                  grupo: item["Projeto (grupo)"]? item["Projeto (grupo)"] : "(SEM)"
              })
            })

           await criarUsuarioEmLote(_usuarios)
            

           onHideNovoUsuarioEmLote()
           onHideNovoUsuario()
           
          }
        }
    }


    const showModalCadastroNovoUsuarioEmLote = () => {
      return(
        <Dialog
          visible={showModalNovoUsuario}
          style={{ width: '450px' }}
          header="Cadastro de Usuário em lote"
          modal
          className='show-modal-cadastro-novo-usuario-em-lote'
          onHide={onHideNovoUsuarioEmLote}
          footer={            
            <React.Fragment>
              <div className="dialog-show-modal-cadastro-novo-usuario-em-lote footer">
                <ButtonLabelComponent label="Processar" icon="pi pi-cloud-upload" onClick={lerArquivoExcel} /> 
                <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideNovoUsuarioEmLote} info={'info'} />
              </div>  
            </React.Fragment>   
          }
        >
          <div className='dialog-show-modal-cadastro-novo-usuario-em-lote conteudo'>
            <a href={`${file}`} target='_blank' rel="noreferrer" download>Baixar arquivo modelo</a>

              <FileUpload  textoDoUpload={<>
                <span>Arraste</span> o arquivo aqui até <br/>
                o retângulo ou<br/>
                <span>clique aqui e escolha</span> o arquivo <br />
                para fazer upload
                </>}
              /> 
          </div>              
        </Dialog>
      )
    }

    const showModalCadastroNovoUsuarioUnico = () => {
      return(<Dialog
        visible={showModalCadastroUnico}
        style={{ width: '450px' }}
        header="Cadastro de usuário unitário"
        modal
        className='show-modal-cadastro-novo-usuario'
        onHide={onHideNovoUsuarioUnico}
        footer={            
          <React.Fragment>
            <div className="dialog-show-modal-cadastro-novo-usuario-unico footer">
              <ButtonLabelComponent label="Salvar" icon="pi pi-save" onClick={() => salvarNovoUsuario()} /> 
              <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideNovoUsuarioUnico} info={'info'} /> 
            </div>  
          </React.Fragment>  
        }
      >
          <div className='dialog-show-modal-cadastro-novo-usuario-unico form'>
            <InputText className='input-textLabel' name='nomeCompleto' placeholder='Nome Completo' value={form.nomeCompleto} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='email' placeholder='E-mail' value={form.email} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='telefone' placeholder='Telefone' value={form.telefone} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='celular' placeholder='Celular' value={form.celular} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='cnpj' placeholder='CNPJ' value={form.cnpj} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='nomeCliente' placeholder='Nome do cliente' value={form.nomeCliente} onChange={handleInputChange} />
            <Dropdown className='perfil-tipoUsuario' name='projeto' placeholder="Projeto" value={form.projeto} options={_projetosRLANET} onChange={handleInputChange} /> 
            <Dropdown className='perfil-tipoUsuario' name='tipoUsuario' placeholder="Tipo de usuário" value={form.tipoUsuario} options={tipoUsuario} onChange={handleInputChange} /> 
          </div>          
          
          <div className="dialog-show-modal-cadastro-novo-usuario-unico form-checkbox flex align-items-center">            
            <Checkbox inputId="ingredient1" name="acessoModuloSeguros" value={form.acessoModuloSeguros} onChange={handleInputChange} checked={form.acessoModuloSeguros} />            
            <label htmlFor="ingredient1" className="ml-2">Seguros</label>

            <Checkbox inputId="ingredient1" name="acessoModuloGestaoDados" value={form.acessoModuloGestaoDados} onChange={handleInputChange} checked={form.acessoModuloGestaoDados} />            
            <label htmlFor="ingredient1" className="ml-2">Gestão de Dados</label>

          </div>

          <div className="dialog-show-modal-cadastro-novo-usuario-unico form-checkbox flex align-items-center">  
            <Checkbox inputId="ingredient1" name="acessoModuloProjetoAcordo" value={form.acessoModuloProjetoAcordo} onChange={handleInputChange} checked={form.acessoModuloProjetoAcordo} />            
            <label htmlFor="ingredient1" className="ml-2">Projeto Acordos</label>

            <Checkbox inputId="ingredient1" name="usuarioAprovador" value={form.usuarioAprovador} onChange={handleInputChange} checked={form.usuarioAprovador} />            
            <label htmlFor="ingredient1" className="ml-2">Usuário Aprovador?</label>
          </div>
      </Dialog>)
    }


    return(
      <div className="modulo-projeto-acordos-usuario">
        <Header /> 
          <div className="projeto-acordo-container">
            <div className="projeto-acordo-menu-left">
              <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('projetoAcordo', user?.perfil)}/>
            </div>
                <div className="projeto-acordo-conteudo">
                  <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >

                      <Toolbar className="mb-4" left={<span className='page-title'>Cadastro de Usuário</span>} right={rightToolbarTemplate}></Toolbar>

                      <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                      <DataTable value={_usuarios} editMode="row" dataKey="uid" rows={5} 
                      paginator
                      currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" rowsPerPageOptions={[5, 10, 25, 50]} 
                      selection={itemSelecionado} onSelectionChange={e => { setItemSelecionado(e.value)} }
                      >
                          <Column selectionMode="multiple" headerStyle={{ width: '1%' }}></Column>
                          <Column field="nome" editor={(options) => textEditor(options)} header="Nome completo" style={{ width: '10%' }}></Column>
                          <Column field="email" editor={(options) => textEditor(options)} header="E-Mail Responsável" style={{ width: '10%' }}></Column>
                          <Column field="telefone" editor={(options) => textEditor(options)} header="Telefone" style={{ width: '5%' }}></Column>
                          <Column field="celular" editor={(options) => textEditor(options)} header="Celular" style={{ width: '5%' }}></Column>
                          <Column field="cnpj" editor={(options) => textEditor(options)} header="CNPJ" style={{ width: '20%' }}></Column>
                          <Column field="nomeCliente" editor={(options) => textEditor(options)} header="Nome do cliente" style={{ width: '20%' }}></Column>
                          <Column field="grupo" editor={(options) => textEditor(options)} header="Projeto" style={{ width: '5%' }}></Column>
                          <Column field="tipoUsuario" editor={(options) => textEditor(options)} header="Tipo de Usuário" style={{ width: '10%' }}></Column>
                          
                          <Column field="perfil.aprovador" editor={(options) => textEditor(options)} header="Usuário Aprovador?" style={{ width: '10%' }}></Column>
                          <Column field="perfil.acessoModuloSeguros" editor={(options) => textEditor(options)} header="Acessa Módulo de Seguros?" style={{ width: '15%' }}></Column>
                          <Column field="perfil.acessoModuloProjetoAcordo" editor={(options) => textEditor(options)} header="Acessa Módulo Projeto Acordo" style={{ width: '15%' }}></Column>
                          <Column field="perfil.acessoModuloPainelGestao" editor={(options) => textEditor(options)} header="Acessa Módulo Gestão de dados" style={{ width: '15%' }}></Column>
                      </DataTable>
                  </div>

                  {showModalNovoUsuario && showModalCadastroNovoUsuario()} 

                  {showModalCadastroEmLote && showModalCadastroNovoUsuarioEmLote()}

                  {showModalCadastroUnico && showModalCadastroNovoUsuarioUnico()}
                </div>
          </div>
      </div>
    )
}

export default Usuario
