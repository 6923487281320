import Icon15 from '../../assets/Icons/15.png'
import Icon9 from '../../assets/Icons/9.png'
import Icon17 from '../../assets/Icons/17.png'
import Icon20 from '../../assets/Icons/20.png'
import Icon16 from '../../assets/Icons/16.png'
import Icon21 from '../../assets/Icons/21.png'
import Icon10 from '../../assets/Icons/10.png'
import Icon1 from '../../assets/Icons/1.png'
import Icon3 from '../../assets/Icons/3.png'
import LogoLegalInsights from '../../assets/Imagens/Logo-Legal-Insights-v2.svg'
import Icon13 from '../../assets/Icons/13.png'
import Icon4 from '../../assets/Icons/4.png'
import Icon3_v4 from '../../assets/Icons/3_v4.png'
import Teste from '../../assets/Icons/teste.svg'
import Icon26 from '../../assets/Icons/26.png'
import Icon3Checked from '../../assets/Icons/3_Checked.png'
import Icon4Checked from '../../assets/Icons/4_checked.png'
import Icon5Close from '../../assets/Icons/6_Close.png'
import Icon7Close from '../../assets/Icons/7_close.png'
import Icon4GoBack from '../../assets/Icons/4_goback.png'
import Icon5 from '../../assets/Icons/5.png'
import Icon13Documento from '../../assets/Icons/13_Documento.png'
import IconSistemaLILogo from '../../assets/Icons/ícones_sistema_LI (Logo) (2).png'
import IconSistemaLILogo4 from '../../assets/Icons/ícones_sistema_LI (Logo) (4).png'
import Icon6 from '../../assets/Icons/6.png'
import Icon18 from '../../assets/Icons/18.png'
import Icon25 from '../../assets/Icons/ícones_sistema_LI (Logo).png'
import Icon26_Cinza from '../../assets/Icons/26_cinza.png'
import IconModuloProjetoAcordos from '../../assets/Icons/IconModuloProjetoAcordos.png'
import IconModuloProjetoAcordosCinza from '../../assets/Icons/IconModuloProjetoAcordosCinza.png'
import IconModuloSegurosCinza from '../../assets/Icons/IconModuloSegurosCinza.png'
import IconModuloSeguros from '../../assets/Icons/IconModuloSeguros.png'
import IconModuloPainelGestao from '../../assets/Icons/IconModuloPainelGestao.png'
import IconModuloPainelGestaoCinza from '../../assets/Icons/IconModuloPainelGestaoCinza.png'
import IconFaleConosco from '../../assets/Icons/faleconosco_19.png'
import IconArquivos from '../../assets/Icons/ícones_sistema_LI (Logo) (3).png'
import IconModuloDepositoJudicial from '../../assets/Icons/IconModuloDepositoJudicial.png'
import IconModuloDepositoJudicialCinza from '../../assets/Icons/ícones_sistema_LI (Logo) (3)Cinza.png'



export const iconsSistema = [
    { iconNome: "Icon15", icon: Icon15 }, 
    { iconNome: "Icon9", icon: Icon9 }, 
    { iconNome: "Icon17", icon: Icon17}, 
    { iconNome: "Icon20", icon: Icon20},
    { iconNome: "Icon16", icon: Icon16},
    { iconNome: "Icon21", icon: Icon21},
    { iconNome: "Icon10", icon: Icon10},
    { iconNome: "Icon1", icon: Icon1},
    { iconNome: "Icon3", icon: Icon3},
    { iconNome: "LogoLegalInsights", icon: LogoLegalInsights},
    { iconNome: "Icon4", icon: Icon4},
    { iconNome: "Teste", icon: Teste},
    { iconNome: "Icon26", icon: Icon26},
    { iconNome: "Icon3Checked", icon: Icon3Checked},
    { iconNome: "Icon4Checked", icon: Icon4Checked},
    { iconNome: "Icon5Close", icon: Icon5Close},
    { iconNome: "Icon7Close", icon: Icon7Close},
    { iconNome: "Icon4GoBack", icon: Icon4GoBack},
    { iconNome: "Icon5", icon: Icon5},
    { iconNome: "Icon13Documento", icon: Icon13Documento},
    { iconNome: "IconSistemaLILogo", icon: IconSistemaLILogo},
    { iconNome: "IconSistemaLILogo4", icon: IconSistemaLILogo4},
    { iconNome: "Icon6", icon: Icon6},
    { iconNome: "Icon18", icon: Icon18},
    { iconNome: "Icon25", icon: Icon25},
    { iconNome: "Icon25", icon: Icon25} ,  
    { iconNome: "Icon26_Cinza", icon: Icon26_Cinza},   
    { iconNome: "IconModuloProjetoAcordos", icon: IconModuloProjetoAcordos},   
    { iconNome: "IconModuloProjetoAcordosCinza", icon: IconModuloProjetoAcordosCinza},
    { iconNome: "IconModuloSegurosCinza", icon: IconModuloSegurosCinza},   
    { iconNome: "IconModuloSeguros", icon: IconModuloSeguros},
    { iconNome: "IconModuloPainelGestao", icon: IconModuloPainelGestao},
    { iconNome: "IconModuloPainelGestaoCinza", icon: IconModuloPainelGestaoCinza},
    { iconNome: "IconFaleConosco", icon: IconFaleConosco},
    { iconNome: "IconArquivos", icon: IconArquivos},
    { iconNome: "IconModuloDepositoJudicial", icon: IconModuloDepositoJudicial},
    { iconNome: "IconModuloDepositoJudicialCinza", icon: IconModuloDepositoJudicialCinza},
]

export const buscarIcon = (iconName) => {
    const icon = iconsSistema.filter( item => String(item.iconNome).toLocaleLowerCase() === String(iconName).toLocaleLowerCase())[0]
    return icon?.icon
}

export const menuLateralOpcoesPorModulos = (nomeModulo, perfil) => {
    const menuLateral = []
    
    if(perfil?.acessoMenuStatusReports){
        menuLateral.push({ key: 1, modulo: 'projetoAcordo', labelMenuOpcao: 'Status Report', icon: Icon3_v4, router: '', id: 'id-menu-status-report', routers: 'projetoAcordo/statusReport'})
        menuLateral.push( { key: 2, modulo: 'projetoAcordo', labelMenuOpcao: 'Aprovações', icon: Icon15, router: '', id: 'id-menu-aprovacoes', routers: 'projetoAcordo/aprovacoes' })
        menuLateral.push( { key: 3, modulo: 'projetoAcordo', labelMenuOpcao: 'Processos', icon: Icon13, router: '', id: 'id-menu-processos', routers: 'projetoAcordo/processos' })
        menuLateral.push( { key: 4, modulo: 'projetoAcordo', labelMenuOpcao: 'Arquivos', icon: IconArquivos, router: '', id: 'id-menu-arquivos', routers: 'projetoAcordo/arquivos' })
        menuLateral.push( { key: 5, modulo: 'projetoAcordo', labelMenuOpcao: 'Fale Conosco', icon: IconFaleConosco, router: '', id: 'id-menu-fale-conosco', routers: '' })
    }

    if(!perfil?.acessoMenuStatusReports){        
        menuLateral.push( { key: 2, modulo: 'projetoAcordo', labelMenuOpcao: 'Aprovações', icon: Icon15, router: '', id: 'id-menu-aprovacoes', routers: 'projetoAcordo/aprovacoes' })
        menuLateral.push( { key: 3, modulo: 'projetoAcordo', labelMenuOpcao: 'Processos', icon: Icon13, router: '', id: 'id-menu-processos', routers: 'projetoAcordo/processos' })
        menuLateral.push( { key: 4, modulo: 'projetoAcordo', labelMenuOpcao: 'Arquivos', icon: IconArquivos, router: '', id: 'id-menu-arquivos', routers: 'projetoAcordo/arquivos' })
        menuLateral.push( { key: 5, modulo: 'projetoAcordo', labelMenuOpcao: 'Fale Conosco', icon: IconFaleConosco, router: '', id: 'id-menu-fale-conosco', routers: '' })
    }

    menuLateral.push( { key: 1, modulo: 'depositoJudicial', labelMenuOpcao: 'Report Substituição', icon: Icon3_v4, router: '', id: 'id-deposito-judicial-menu-report-substituicao', routers: 'depositoJudicial/statusReport' })
    menuLateral.push( { key: 2, modulo: 'depositoJudicial', labelMenuOpcao: 'Levantamento', icon: Icon13, router: '', id: 'id-deposito-judicial-menu-levantamento', routers: 'depositoJudicial/levantamento' })
    menuLateral.push( { key: 3, modulo: 'depositoJudicial', labelMenuOpcao: 'Conciliação', icon: Icon15, router: '', id: 'id-deposito-judicial-menu-conciliacao', routers: 'depositoJudicial/conciliacao' })
    
    
    const menuLateralPorModulo = menuLateral.filter( item => item.modulo.toLocaleLowerCase() === nomeModulo.toLocaleLowerCase())
    return menuLateralPorModulo
}

export const consoleTable = (metodo, response) => {
    console.table([{
        metodo: metodo, 
        statusCode: response?.status? response.status : 500, 
        statusText: response?.statusText? response?.statusText : 'Erro não tratado',
        data: response?.data?.message? response.data.message : 'Mensagem não tratada'
    }])
}