
import { Routes, Route } from 'react-router-dom';
import React from 'react'

//Components
import SignIn from '../pages/SignIn'
import MeuPerfil from '../pages/MeuPerfil'
import EsqueceuSenha from '../pages/EsqueceuSenha'
import RequireAuth from './requireAuth'
import Higienizacao from '../pages/Higienizacao/Home';
import CadastrarSe from '../pages/CadastrarSe';
import CadastroPesssoaJuridica from '../pages/CadastroPessoaJuridica';
import CadastroPessoaFisica from '../pages/CadastroPessoaFisica';
import Modulos from '../pages/Modulos';
import PrimeiroAcesso from '../pages/PrimeiroAcesso';
import ModuloProjetoAcordos from '../pages/Modulos/ModuloProjetoAcordos';
import ProjetoAcordoAprovacoes from '../pages/Modulos/ModuloProjetoAcordos/Aprovacoes';
import ProjetoAcordoProcessos from '../pages/Modulos/ModuloProjetoAcordos/Processos';
import ProjetoAcordoStatusReport from '../pages/Modulos/ModuloProjetoAcordos/StatusReport';
import AlterarSenha from '../pages/AlterarSenha';
import Usuario from '../pages/Usuarios';
import ProjetoAcordoArquivos from '../pages/Modulos/ModuloProjetoAcordos/Arquivos';
import ModuloDepositoJudicial from '../pages/Modulos/ModuloDepositoJudicial';
import DepositoJudicialStatusReport from '../pages/Modulos/ModuloDepositoJudicial/StatusReport';
import DepositoJudicialLevantamento from '../pages/Modulos/ModuloDepositoJudicial/Levantamento';
import DepositoJudicialConciliacao from '../pages/Modulos/ModuloDepositoJudicial/Conciliacao';
import ControleAcesso from '../pages/Modulos/ModuloProjetoAcordos/ControleAcesso';

export default function Rotas() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth redirectTo="/modulos" >
            <SignIn />
          </RequireAuth>
        }
      />

      <Route
        path="/register"
        element={
          <RequireAuth redirectTo="/higienizacao">
            {/* <SignUp /> */}
          </RequireAuth>
        }
      />


      <Route
        path="/meuperfil"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <MeuPerfil />
          </RequireAuth>
        }
      />


      <Route
        path="/esqueceuSenha"
        element={
          <RequireAuth redirectTo="/">
            <EsqueceuSenha />
          </RequireAuth>
        }
      />

      <Route
        path="/higienizacao"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Higienizacao />
          </RequireAuth>
        }
      />

      <Route
        path="/cadastrarSe"
        element={
          <RequireAuth redirectTo="/" >
            <CadastrarSe />
          </RequireAuth>
        }
      />

      <Route
        path="/cadastroPessoaJuridica"
        element={
          <RequireAuth redirectTo="/">
            <CadastroPesssoaJuridica />
          </RequireAuth>
        }
      />

      <Route
        path="/cadastroPessoaFisica"
        element={
          <RequireAuth redirectTo="/">
            <CadastroPessoaFisica />
          </RequireAuth>
        }
      />

      <Route
        path="/modulos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Modulos />
          </RequireAuth>
        }
      />


      <Route
        path="/primeiroAcesso"
        element={
          <RequireAuth redirectTo="/">
            <PrimeiroAcesso />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ModuloProjetoAcordos />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo/statusReport"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ProjetoAcordoStatusReport />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo/aprovacoes"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ProjetoAcordoAprovacoes />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo/processos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ProjetoAcordoProcessos />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo/alterarSenha"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <AlterarSenha />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo/usuario"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Usuario />
          </RequireAuth>
        }
      />


      <Route
        path="/projetoAcordo/arquivos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ProjetoAcordoArquivos />
          </RequireAuth>
        }
      />

      <Route
        path="/depositoJudicial"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ModuloDepositoJudicial />
          </RequireAuth>
        }
      />

      <Route
        path="/depositoJudicial/statusReport"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <DepositoJudicialStatusReport />
          </RequireAuth>
        }
      />

      <Route
        path="/depositoJudicial/levantamento"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <DepositoJudicialLevantamento />
          </RequireAuth>
        }
      />

      <Route
        path="/depositoJudicial/conciliacao"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <DepositoJudicialConciliacao />
          </RequireAuth>
        }
      />

      <Route
        path="/projetoAcordo/controleAcesso"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ControleAcesso />
          </RequireAuth>
        }
      />
    </Routes>
  )
}