import React from 'react';
import PropTypes from 'prop-types'

// import {
//     DataGridPro,
//     ptBR
// } from '@mui/x-data-grid-pro';
import { DataGrid, ptBR} from '@mui/x-data-grid'
import { useContext, useState } from 'react';
import { CrudContext } from '../../../contexts/crud';

import './index.css'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext';

const DataGridCustom = ({ column, rows, selection }) => {
    const { setFormConteudo } = useContext(CrudContext)
    const { alterColumnVisibility, columnVisibilityModel } = useContext(LegalInsightsCrudContext)
    const [pageSize, setPageSize] = useState(10);

    // const alterColumnVisibility = (model) => {
    //     setColumnVisibilityModel(model);
    // }

    const handleSaveClick = (e) =>{       
        setFormConteudo({ [e.field]: e.value, id: e.id })        
    }

    return(<div style={{ height: '100%', width: '100%' }}>
        <DataGrid id="data-Grid-Pro" className='data-Grid-Pro'
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            columns={column}
            rows={rows}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                alterColumnVisibility(newModel)
            }
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pageSize={pageSize}
            pagination
            checkboxSelection={selection}            
            onSelectionModelChange={(newSelectionModel) => {
                setFormConteudo(newSelectionModel)
            }}
            onCellEditCommit={handleSaveClick}
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd' }       
            disableColumnReorder={true}
        />
    </div>)
}


DataGridCustom.propTypes ={
    column: PropTypes.array,
    rows: PropTypes.array,
    selection: PropTypes.bool
}


export default DataGridCustom