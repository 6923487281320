import React, { useContext, useEffect } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { ImportacaoContext } from '../../../../contexts/importacao'
import { Box } from '@mui/material'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'

const DepositoJudicialConciliacao = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload, alterColumnVisibility } = useContext(LegalInsightsCrudContext)
    const {  load }  = useContext(ImportacaoContext)
    const { user } = useContext(AuthContext)

    useEffect(()=> { 
        alterColumnVisibility({ id: false })
    },[])


    const conciliacaoMock = [
        {id: '1', processo: '0016600-60.2001.5.02.0332', vara: '365', status: '', honorariosPact: '', checklist: '', documentos: '', apolice: ''}
    ]
    
    const widthPadrao = 250
    const columns = [{
        "field": "id",
        "hide": true,
        "width": 80,
    },
    
    {
        "field": "processo",
        "headerName": "Processo",
        "width": widthPadrao,
        "hide": false
    },
    
    {
        "field": "vara",
        "headerName": "Vara",
        "width": widthPadrao,
        "editable": false,
    },
    {
        "field": "status",
        "headerName": "Status",
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "honorariosPact",
        "headerName": "Honorários Pact",
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "checklist",
        "headerName": "Checklist",        
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "documentos",
        "headerName": "Documentos",        
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "statusPact",
        "headerName": "Status Pact",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "apolice",
        "headerName": "Apólice",        
        "width": widthPadrao,
        "editable": false
    }
    ]



    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header">                                
                            </div>                                                        
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <DataGridCustom 
                                            column={columns} 
                                            rows={conciliacaoMock} 
                                            selection={false}              
                                        />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default DepositoJudicialConciliacao
