import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import axios from 'axios';
import { showToast } from '../../utils/Compartilhados/CustomToast';

export const AuthContext = createContext({})


function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loadingAuth, setLoadingAuth] = useState(false);
    const [usuarios, setUsuarios] = useState(false);
    const [load, setLoad] = useState(true);
    const [isHumburguerActive, setIsHumburguerActive] = useState(false)
    const [isModel, setIsModel] = useState(false)
    const [isConfig, setIsConfig] = useState(false);
    const [isSelected, setIsSelected] = useState(0)
    const [isDash, setIsDash] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [isHigienizacao, setIsHigienizacao] = useState(false);
    const [showConfig, setShowConfig] = useState(false)
    const [token, setToken] = useState()
    const [emailUsuario, setEmailUsuario] = useState('')
    const [codigoSeguranca, setCodigoSeguranca] = useState('')
    const [isPrimeiroAcesso, setIsPrimeiroAcesso] = useState(false)
    const [usuarioAuth, setUsuarioAuth] = useState('')
    const [controleAcesso, setControleAcesso] = useState([])
    

    const urlBase = process.env.REACT_APP_PACT_API;    

    useEffect(() => {

        const storageUser = localStorage.getItem('SistemaUser');

        if (storageUser) {
            setUser(JSON.parse(storageUser));
            setLoad(false);
        }

        setLoad(false);

    }, [])


    async function signUp(data) {
        setLoadingAuth(true);

        await axios.post(urlBase + '/user/create',
            data, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function () {
                toast.success('Usuario cadastrado com sucesso');
            })
            .catch(err => {
                (err.response.data.erro) ? toast.error(err.response.data.erro) : toast.error('Algo deu errado')
            })
    }

    async function updateAvatar(data) {
        setLoadingAuth(true);

        await axios.post(urlBase + '/user/updateAvatar',
            data, {
            headers: {
                'Authorization': `token ${user.token}`
            }
            })
            .then(function (response) {

                let _data = response.data
                _data.token = user.token
                setUser(_data);
                storageUser(_data);
                toast.success('Usuario alterado com sucesso');
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function removeUser(id) {
        setLoadingAuth(true);

        await axios.put(urlBase + '/user/updatestatus',
            {
                id: id,
                status: 'Inativo'
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function () {               
                getAllUsers("Ativo");
                setLoadingAuth(false);
            })
            .catch((err) => {
                console.log(err)
                toast.error('Algo deu errado')
                setLoadingAuth(false);
            })
    }

    async function ativarUser(id) {
        setLoadingAuth(true);

        await axios.put(urlBase + '/user/updatestatus',
            {
                id: id,
                status: 'Ativo'
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function () {
                getAllUsers("Inativo");
                setLoadingAuth(false);
            })
            .catch((err) => {
                console.log(err)
                toast.error('Algo deu errado')
                setLoadingAuth(false);
            })
    }


    function storageUser(data) {
        if(data != undefined){
            localStorage.removeItem('SistemaUser');
            localStorage.setItem('SistemaUser', JSON.stringify(data))
        }        
    }

    async function signOut() {
        let usuario =  JSON.parse(localStorage.getItem('SistemaUser'))
        localStorage.removeItem('SistemaUser');
        
        setUser(null);
        setToken(null);

        const response = await axios.post(`${urlBase}/user/signOut`, { email: usuario.email.toLowerCase() })
        .then((response) => {
            return response
        })
        .catch((error) => {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)                 
            }
            else {
                toast.error('Algo deu errado')            
            }  
        })
        showToast('success',response.data.message)

    }

    async function signIn(email, password) {
        
        const response = await axios.post(urlBase + '/user/auth',
            {
                email: email.toLowerCase(),
                password: password
            }
        )
            .then(function (response) {
                if(!response.data.isPrimeiroAcesso){                    
                    storageUser(response.data); 
                    setUser(response.data);
                }                
                setUsuarioAuth(response.data)
                setToken(response.data.token)
                showToast('success', 'Bem vindo de volta!')
                return response.data
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    toast.error(err?.response?.data?.message)                 
                }
                else {
                    toast.error('Algo deu errado')            
                }            
            })
            
            return response 
            
    }

    async function forgot(email) {
        setLoad(true)

        await axios.put(urlBase + '/user/forgotPassword',
            {
                email: email,
            }
        )
            .then(function () {
                toast.success('Verifique seu e-mail para alterar a senha');
            })
            .catch((err) => {
                console.log("Update error", err);
                toast.error(err.response.data.messsage)
            })

        setLoad(false)

    }

    async function getAllUsers(status) {

        await axios.get(urlBase + '/user/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateState(response.data, status)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            }).finally(() => {
                setLoad(false)
            })

    }

    async function updateState(listaUsuarios, status) {
        const isCollectionEmpty = listaUsuarios.length === 0;
        if (!isCollectionEmpty) {
            let listaFinal = [...listaUsuarios]

            if (status === "Ativo") {
                listaFinal = listaUsuarios.filter(item => item.status === "Ativo")
            }
            else {
                listaFinal = listaUsuarios.filter(item => item.status === "Inativo")
            }

            setUsuarios(listaFinal)
        }
    }

    async function updateUser(user) {
        const payloadUsuario ={
            uid: user.key,
            nome: user.nome,
            tipoUsuario: user.tipoUsuario,
            departamento: user.departamento,
            funcao: user.funcao,
            cliente: user.empresa,
            token: user.token
        }
        await axios.put(urlBase + '/user/alterUser',
        payloadUsuario,
        {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function () {
                getAllUsers(1);
                toast.success('Alterado com sucesso')
            })
            .catch((err) => {
                console.log("Error auth.js updateUser: ", err);
                toast.error('Erro')
            })
    }

    async function updateMyUser(user) {
        await axios.put(urlBase + '/user/alterUser',
            {
                uid: user.key,
                nome: user.nome,
                tipoUsuario: user.tipoUsuario,
                departamento: user.departamento,
                funcao: user.funcao,
                cliente: user.cliente
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                response.data.token = user.token
                setUser(response.data)
                storageUser(response.data)
                toast.success('Alterado com sucesso')
            })
            .catch((err) => {
                console.log("updateMyUser | POST-Error: ", err);
                toast.error('Erro')
            })
    }

    async function updatePassword(email, senhaAtual, novaSenha, confirmarNovaSenha) {
        let ret = null
        ret = await axios.put(urlBase + '/user/alterPassword',
            {
                email: email,
                correntPassword: senhaAtual,
                newPassword: novaSenha,
                confirmPassword: confirmarNovaSenha
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
               showToast('success','Senha Alterada com sucesso!')
                return response
            })
            .catch((err) => {
                showToast('warning',err.response.data.message)                
                return err.response.data.message
            })

        return ret;
    }

    const alterPasswordLegalInsights = async (email, novaSenha, confirmarSenha) => {
        
        const response = await axios.put(urlBase + '/user/alterPasswordLegalInsights',
            {
                email,
                novaSenha,
                confirmarSenha
            },
            {
                headers: {
                    'Authorization': `token ${token}`
                }
            }
        ).then(function (response) {
               showToast('success','Senha Alterada com sucesso')
                return response
        }).catch((err) => {
                toast.error(err.response.data.messsage)
                return err.response.data.message
        })

        return response;
    }

    const usuarioConectados = async () =>{
        await axios.get(`${urlBase}/user/controleAcesso`)
        .then((response) => {
            setControleAcesso(response.data)
        })
        .catch((error) => {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message)                 
            }
            else {
                toast.error('Algo deu errado')            
            }  
        })
        setLoad(false)
    }
    
    return (
        <AuthContext.Provider value={{
            signed: !!user,
            user,
            load,
            signUp,
            signOut,
            signIn,
            loadingAuth,
            setUser,
            storageUser,
            getAllUsers,
            usuarios,
            isHumburguerActive,
            setIsHumburguerActive,
            removeUser,
            updateUser,
            updateMyUser,
            isModel,
            setIsModel,
            isConfig,
            setIsConfig,
            forgot,
            isSelected,
            setIsSelected,
            ativarUser,
            isDash,
            setIsDash,
            showConfig,
            setShowConfig,
            updateAvatar,
            updatePassword,
            token,
            isProject,
            setIsProject,
            isHigienizacao,
            setIsHigienizacao,
            emailUsuario, setEmailUsuario,
            codigoSeguranca, setCodigoSeguranca,
            isPrimeiroAcesso, setIsPrimeiroAcesso,
            alterPasswordLegalInsights,
            setUsuarioAuth, usuarioAuth,
            controleAcesso, setControleAcesso,
            usuarioConectados
        }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node,
}
export default AuthProvider;