import React, { useContext, useState, useEffect } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import { ButtonLabelComponent, ButtonLabelSearchComponent } from '../../../../Components/Commons/Button'
import './index.css'
import { ImportacaoContext } from '../../../../contexts/importacao'
import { Box } from '@mui/material'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import moment from 'moment'
import { currencyFormatter, exportExcel } from '../../../../utils/data'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import useForm from '../../../../hooks/useForm'
import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'

const ProjetoAcordoProcessos = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload, setMenuSelecionado, alterColumnVisibility } = useContext(LegalInsightsCrudContext)
    const { modeloUm, getImportacao, load }  = useContext(ImportacaoContext)
    const { user } = useContext(AuthContext)
    const [meusProcessos, setMeusProcessos] = useState([])
    const [form, handleInputChange, clear] = useForm({ numeroCNJ: '' })


    useEffect(()=> { 
        getImportacao() 
        alterColumnVisibility({ id: false })
    },[])

    useEffect(()=>{     
        setMenuSelecionado("id-menu-processos")
        setMeusProcessos(modeloUm)
    },[modeloUm])


    const columns = [{
        "field": "id",
        "hide": true,
        "width": 80,
    },
    
    {
        "field": "NCNJ",
        "headerName": "Processo",
        "width": 200,
        "hide": false
    },
    
    {
        "field": "advogadoReverso_oab",
        "headerName": "Advogado Adverso",
        "width": 400,
        "editable": false,
    },
    {
        "field": "ecBase",
        "headerName": "Escritório de Base",
        "width": 150,
        "editable": false
    },

    {
        "field": "status",
        "headerName": "Status",
        "width": 150,
        "editable": false
    },

    {
        "field": "fase",
        "headerName": "Fase",        
        "width": 120,
        "editable": false
    },

    {
        "field": "momento",
        "headerName": "Momento",        
        "width": 300,
        "editable": false
    },

    {
        "field": "status_pact",
        "headerName": "Status Pact",
        "width": 250,
        "editable": false
    },
    {
        "field": "provisao",
        "headerName": "Provisão",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    },

    {
        "field": "risco_bruto",
        "headerName": "Risco Bruto",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    },

    {
        "field": "gatilho_bruto",
        "headerName": "Gatilho Bruto",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    },

    {
        "field": "termometro",
        "headerName": "Termometro",
        "type": "singleSelect",
        "valueOptions": 'termometro',
        "width": 250,
        "editable": false
    },

    {
        "field": "status_negociacao",
        "headerName": "Status da Negociação",
        "type": "singleSelect",
        "valueOptions": 'statusNegociacao',
        "width": 250,
        "editable": false
    },

    {
        "field": "acordo_bruto",
        "headerName": "Acordo Bruto",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    },

    {
        "field": "acordo_liquido",
        "headerName": "Acordo Líquido",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    },

    {
        "field": "desagio",
        "headerName": "Deságio",
        "type": "text",
        "valueFormatter": ({ value }) => `${(value / 100).toFixed(2)}%`,
        "width": 250,
        "editable": false
    },

    {
        "field": "honorario_pact",
        "headerName": "Honorário PACT",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    },

    {
        "field": "data_protocolo",
        "headerName": "Data do Protocolo",
        "type": "text",
        "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
        "width": 250,
        "editable": false
    },

    {
        "field": "data_homologacao",
        "headerName": "Data da Homologação",
        "type": "text",
        "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
        "width": 250,
        "editable": false
    },

    {
        "field": "status_pre",
        "headerName": "Observação Status Pré",
        "width": 250,
        "editable": false
    },
    {
        "field": "custasProcessuais",
        "headerName": "$ Custas Processuais",
        "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
        "width": 250,
        "editable": false
    }
    ]

    const handleOnClick = () => {        
        showToast('warning','Seu download está em andamento. Por favor, aguarde.') 
        exportExcel(meusProcessos)        
    }

    const handleSearchProcess = () => {        
        const meusProcessosFiltro = meusProcessos.filter( item => item.NCNJ.includes(form.numeroCNJ))
        
        if(meusProcessosFiltro.length === 0 ){
            return showToast('warning','Nenhum registro encontrado!')
        } 

        setMeusProcessos(meusProcessosFiltro)
    }

    const handleLimparFiltro = async () => {
        showToast('warning','Aguarde, os dados serão atualizados em alguns segundos!')
        await getImportacao()
        clear()        
    }

    return(
        <div className="modulo-projeto-acordos-container">
            <Header />   

            <div className="projeto-acordo-container">
                <div className="projeto-acordo-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('projetoAcordo', user?.perfil)}/>
                </div>

                <div className="projeto-acordo-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header">
                                <ButtonLabelSearchComponent label={'Pesquisar por CNJ'} value={form.numeroCNJ} name={'numeroCNJ'} onchange={handleInputChange} onClick={handleSearchProcess}/> 
                                <div>
                                    <ButtonLabelComponent label={`${loadingDownload? 'Aguarde...' : 'Exportar' }`} onClick={handleOnClick} disabled={loadingDownload? true : false }/>                                
                                    <ButtonLabelComponent label={`Limpar filtro`} onClick={handleLimparFiltro} info />                                
                                </div>
                                
                            </div>                            
                            
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <DataGridCustom 
                                            column={columns} 
                                            rows={meusProcessos} 
                                            selection={false}              
                                        />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="projeto-acordo-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default ProjetoAcordoProcessos
