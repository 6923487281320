import React from "react"
import PropTypes from 'prop-types'
import { Button } from "primereact/button"

import './index.css'
import { buscarIcon } from "../../../utils/Compartilhados"
import { Input } from '@mui/material'

const btnPosition = {
    LEFT: 'left',
    RIGHT: 'right'
}

const validarInfo = (info) => {
    switch(info){
        case 'info':
            return ' info'
        case 'danger':
            return ' p-button-danger'
        case 'alert':
            return ' p-button-alert'
        case 'success':
            return ' p-button-success mr-2'
        default:
            return ''
    }
}

export const ButtonLabelComponent = ({ label, onClick, position, disabled, index, info, type, icon }) =>{

    return(<Button 
            className={`button-component-${position === 1? btnPosition.LEFT : btnPosition.RIGHT}${validarInfo(info)? validarInfo(info) : '' }${type === 'cancelar'? '-cancelar' : ''}`} 
            label={label} 
            onClick={onClick} 
            disabled={disabled? disabled : false }
            id={index}
            value={index}
            icon={icon? icon : ''}
            name={label}
        />)
}

ButtonLabelComponent.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    position: PropTypes.number,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    info: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string
}

export const ButtonLabelSearchComponent = ({ name, label, onClick, onchange, value }) => {
    return(
        <Button 
            className={`button-component-search`}             
            placeholder="label"
                  
        >
            <Input name={name} value={value} placeholder={label} onChange={onchange} />
            <img src={buscarIcon('Icon4')} alt="" className="btn-icon-search"  onClick={onClick}/>
        </Button>
    )
}

ButtonLabelSearchComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onchange: PropTypes.func,
    value: PropTypes.string
}