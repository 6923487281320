import React, { useContext, useEffect } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { ImportacaoContext } from '../../../../contexts/importacao'
import { Box } from '@mui/material'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'

const DepositoJudicialLevantamento = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload, alterColumnVisibility } = useContext(LegalInsightsCrudContext)
    const { load }  = useContext(ImportacaoContext)
    const { user } = useContext(AuthContext)


    useEffect(()=> { 
        alterColumnVisibility({ id: false })
    },[])

    const widthPadrao = 250
    const columns = [{
        "field": "id",
        "hide": true,
        "width": 80,
    },
    
    {
        "field": "empresa",
        "headerName": "Empresa",
        "width": widthPadrao,
        "hide": false
    },
    
    {
        "field": "cnpj",
        "headerName": "CNPJ",
        "width": widthPadrao,
        "editable": false,
    },
    {
        "field": "autor",
        "headerName": "Autor",
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "processoLocalizado",
        "headerName": "Processo Localizado",
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "natureza",
        "headerName": "Natureza",        
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "processo",
        "headerName": "Processo",        
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "tribunal",
        "headerName": "Tribunal",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "vara",
        "headerName": "Vara",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "comarca",
        "headerName": "Comarca",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "tipo",
        "headerName": "tipo",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "status",
        "headerName": "Status",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataArquivamento",
        "headerName": "Data Arquivamento",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "enderecar",
        "headerName": "Encereçar",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "momentoProcessual",
        "headerName": "MomentoProcessual",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "instancia",
        "headerName": "Instância",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "advogadoResponsavel",
        "headerName": "Advogado Responsável",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "valorOriginalDeposito",
        "headerName": "Valor Original Depósito",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataDeposito",
        "headerName": "Data do Depósito",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "valorLevantado",
        "headerName": "Valor Levantado",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "saldoAtualizado",
        "headerName": "Saldo Atualizado",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataSaldo",
        "headerName": "Data do Saldo",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "jurosCorrecao",
        "headerName": "Juros e Correção",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "valorRecuperacao",
        "headerName": "Valor Recuperação",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "valorParaGestao",
        "headerName": "Valor Para Gestão",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "tipoConta",
        "headerName": "Tipo de Conta",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "banco",
        "headerName": "Banco",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "numeroContaJudicial",
        "headerName": "Número da Conta Judicial",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "numeroApolice",
        "headerName": "Número da Apólice",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "vencimentoApolice",
        "headerName": "Vencimento Apólice",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "seguradora",
        "headerName": "Seguradora",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "faseProjeto",
        "headerName": "Fase do Projeto",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "responsavel",
        "headerName": "Responsável",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataCadastro",
        "headerName": "Data do Cadastro",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "proximaFase",
        "headerName": "Próxima Fase",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataPeticao",
        "headerName": "Data da Petição",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataLiberacao",
        "headerName": "Data de Liberação",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataEntradaBanco",
        "headerName": "Data Entrada Banco",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataPrevistaPagamento",
        "headerName": "Data Prevista Pagamento",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "valorCreditado",
        "headerName": "Valor Creditado",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataCredito",
        "headerName": "Data Crédito",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "contaCreditada",
        "headerName": "Conta Creditada",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "valorConciliado",
        "headerName": "Valor Conciliado",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "baixaContabil",
        "headerName": "Baixa Contábil",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "controleEmpresa",
        "headerName": "Controle Empresa",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "emissaoNota",
        "headerName": "Emissão de Nota",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "apontamentoObservacoes",
        "headerName": "Apontamento e Observações",
        "width": widthPadrao,
        "editable": false
    }

    ]

    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header">                                
                            </div>                            
                            
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <DataGridCustom 
                                            column={columns} 
                                            rows={[]} 
                                            selection={false}              
                                        />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default DepositoJudicialLevantamento
