import { toast } from 'react-toastify'
import * as XLSX from 'xlsx';

export const modelo = {
    revelia: {
        columnName: "Revelia?",
        fieldName: "revelia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Sim - Outra reclamada', value: 'Sim - Outra reclamada' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' },
        ],
    },

    reclamanteOuvidaAud: {
        columnName: "Reclamante foi ouvido em audiência?",
        fieldName: "reclamanteOuvidaAud",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    testemunhaReclamada: {
        columnName: "Testemunha reclamada?",
        fieldName: "testemunhaReclamada",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    testemunhaReclamante: {
        columnName: "Testemunha reclamante?",
        fieldName: "testemunhaReclamante",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    houvePericia: {
        columnName: "Houve perícia?",
        fieldName: "houvePericia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    resultadoDaPericiaInsalubridade: {
        columnName: "Resultado da perícia Insalubridade?",
        fieldName: "resultadoInsalubridade",
        lista: [
            { label: 'Há insalubridade', value: 'Há insalubridade' },
            { label: 'Não há insalubridade', value: 'Não há insalubridade' }
        ]
    },

    agenteInsalubre: {
        columnName: "Agente Insalubre",
        fieldName: "agenteInsalubre",
        lista: [
            { label: 'Biológicos', value: 'Biológicos' },
            { label: 'Físicos', value: 'Físicos' },
            { label: 'Químicos', value: 'Químicos' }
        ]
    },

    graudaInsalubridade: {
        columnName: "Grau da Insalubridade",
        fieldName: "graudaInsalubridade",
        lista: [
            { label: '10%', value: '10%' },
            { label: '20%', value: '20%' },
            { label: '40%', value: '40%' }
        ]
    },


    resultadoPericulosidade: {
        columnName: "Resultado Periculosidade",
        fieldName: "resultadoPericulosidade",
        lista: [
            { label: 'Há periculosidade', value: 'Há periculosidade' },
            { label: 'Não há periculosidade', value: 'Não há periculosidade' }
        ]
    },

    agentePericulosidade: {
        columnName: "# Agente Periculosidade",
        fieldName: "agentePericulosidade",
        lista: [
            { label: 'Explosivos', value: 'Explosivos' },
            { label: 'Radiações ionizantes ou substâncias radioativas', value: 'Radiações ionizantes ou substâncias radioativas' },
            { label: 'Exposição a roubos ou outras violências físicas', value: 'Exposição a roubos ou outras violências físicas' },
            { label: 'Energia elétrica', value: 'Energia elétrica' },
            { label: 'Uso de motocicleta', value: 'Uso de motocicleta' }
        ]
    },

    resultadoDoenca: {
        columnName: "Resultado Doença?",
        fieldName: "resultadoDoenca",
        lista: [
            { label: 'Há nexo causal', value: 'Há nexo causal' },
            { label: 'Há nexo concausal', value: 'Há nexo concausal' },
            { label: 'Não há nexo causal', value: 'Não há nexo causal' }
        ]
    },

    resultadoAcidente: {
        columnName: "Resultado Acidente",
        fieldName: "resultadoAcidente",
        lista: [
            { label: 'Reconhecido o acidente de trabalho', value: 'Reconhecido o acidente de trabalho' },
            { label: 'Não reconhecido o acidente de trabalho', value: 'Não reconhecido o acidente de trabalho' }
        ]
    },

    houveReformanoAcordaodeRO: {
        columnName: "Houve reforma no Acórdão de RO?",
        fieldName: "houveReformanoAcordaodeRO",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ]
    },

    acordaoReformouparaImprocedencia: {
        columnName: "Acórdão reformou para improcedência?",
        fieldName: "acordaoReformouparaImprocedencia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    houveReformaNoAcordaoRR: {
        columnName: "Houve reforma no acórdão RR? Se sim, qual",
        fieldName: "houveReformaNoAcordaoRR",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' },
            { label: 'Acordo homologado antes do momento', value: 'Acordo homologado antes do momento' }
        ]
    },

    rrAdmitido: {
        columnName: "RR Admitido?",
        fieldName: "rrAdmitido",
        lista: [
            { label: 'Admitido', value: 'Admitido' },
            { label: 'Não admitido', value: 'Não admitido' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    deQuemFoiCalcHomologado: {
        columnName: "De quem foi o calc Homologado",
        fieldName: "deQuemFoiCalcHomologado",
        lista: [
            { label: 'Reclamada', value: 'Reclamada' },
            { label: 'Reclamante', value: 'Reclamante' },
            { label: 'Perito', value: 'Perito' },
            { label: 'Vara/Secretaria', value: 'Vara/Secretaria' }
        ]
    },

    status: {
        columnName: "Status",
        fieldName: "Status",
        lista: [
            { label: 'Ativo', value: 'Ativo' },
            { label: 'Baixado', value: 'Baixado' },
            { label: 'Encerrado', value: 'Encerrado' },
            { label: 'Suspenso', value: 'Suspenso' }
        ]
    },


    fase: {
        columnName: "Fase",
        fieldName: "Fase",
        lista: [
            { label: 'Conhecimento', value: 'Conhecimento' },
            { label: 'Recursal', value: 'Recursal' },
            { label: 'Execução', value: 'Execução' },
            { label: 'todas', value: 'todas' }
        ]
    },
}

export const modeloTresData = {
    revelia: {
        columnName: "Revelia?",
        fieldName: "revelia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Sim - Outra reclamada', value: 'Sim - Outra reclamada' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' },
        ],
    },
}

export const tipoArquivo = [
    { label: "Cálculo", value: "Cálculo" },
    { label: "Cópia", value: "Cópia" },
    { label: "Base de Provisão", value: "Base de Provisão" },
    { label: "Plano de Projeto", value: "Plano de Projeto" },
    { label: "Outros", value: "Outros" }
]

export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export function dataStatus(dados) {

    let dataPizza = null;
    let dadosagrupados = null;

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.status]) {
                acumulador[dado.status] = [];
            }
            acumulador[dado.status].push(dado)
            return acumulador;
        }, {})
    }

    dataPizza = {
        options3: {
            labels: ['Ativo', 'Baixado', 'Encerrado', 'Suspenso'],
            dataLabels: {
                enabled: true,
                formatter: function (val, opt) {
                    return val.toFixed(2) + "%" + " " + "(" + opt.w.config.series[opt.seriesIndex] + ")"
                },
            },
            title: {
                text: 'Status Processual',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            colors: ['#203864', '#B4C7E7', '#8FAADC', '#4472C4']
        },
        series3: [dadosagrupados.Ativo?.length ? dadosagrupados.Ativo.length : 0,
        dadosagrupados.Baixado?.length ? dadosagrupados.Baixado.length : 0,
        dadosagrupados.Encerrado?.length ? dadosagrupados.Encerrado.length : 0,
        dadosagrupados.Suspenso?.length ? dadosagrupados.Suspenso.length : 0]
    }

    return dataPizza;
}

export function dataFase(dados) {

    let dataDonnut = null;
    let dadosagrupados = null;

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.fase]) {
                acumulador[dado.fase] = [];
            }
            acumulador[dado.fase].push(dado)
            return acumulador;
        }, {})
    }



    dataDonnut = {
        options3: {
            labels: ['Conhecimento', 'Recursal', 'Execução'],
            colors: ['#203864', '#4472C4', '#B4C7E7'],
            dataLabels: {
                enabled: true,
                formatter: function (val, opt) {
                    return val.toFixed(2) + "%" + " " + "(" + opt.w.config.series[opt.seriesIndex] + ")"
                },
            },
            title: {
                text: 'Fase Processual',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            }
        },
        series3: [dadosagrupados['1 - Conhecimento']?.length ? dadosagrupados['1 - Conhecimento'].length : 0,
        dadosagrupados['2 - Recursal']?.length ? dadosagrupados['2 - Recursal'].length : 0,
        dadosagrupados['3 - Execução']?.length ? dadosagrupados['3 - Execução'].length : 0]
    }

    return dataDonnut;
}

export const uflist = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
];


export const exportExcel = (modeloUmFilter) => {

    let infoLinha = [];
    let modeloUmListIds = [] 

    modeloUmFilter.forEach( item => {
        modeloUmListIds.push(item.id)
    })


    if (modeloUmFilter.length > 0) {
        let productsFiltered = [];

        modeloUmFilter.forEach((item) => {
            let index = modeloUmListIds.indexOf(item.NCNJ)

            if (index !== -1) {
                infoLinha = [];
                // if (_Basico) {
                    infoLinha.NCNJ = item.NCNJ                 
                    infoLinha.advogadoReverso_oab = item.advogadoReverso_oab                 
                    infoLinha.ecBase = item.ecBase
                // }

                // if (_Fase) {
                    infoLinha.status = item.status
                    infoLinha.fase = item.fase
                    infoLinha.momento = item.momento
                // }

               
                // if (_acordos) {                   
                    infoLinha.status_pact = item.status_pact                    
                    infoLinha.status_pre = item.status_pre                    
                    infoLinha.provisao = item.provisao
                    infoLinha.risco_bruto = item.risco_bruto                    
                    infoLinha.gatilho_bruto = item.gatilho_bruto
                    infoLinha.status_negociacao = item.status_negociacao
                    infoLinha.termometro = item.termometro
                    infoLinha.data_protocolo = item.data_protocolo
                    infoLinha.data_homologacao = item.data_homologacao
                    infoLinha.acordo_bruto = item.acordo_bruto
                    infoLinha.acordo_liquido = item.acordo_liquido
                    infoLinha.status_minutas = item.status_minutas
                    infoLinha.honorario_pact = item.honorario_pact
                    infoLinha.desagio_net = item.desagio_net
                // }

                // if (_Financeira) {                    
                    infoLinha.custasProcessuais = item.custasProcessuais? currencyFormatter.format(item.custasProcessuais.replace(',', '.')) : ''
                // }                

                productsFiltered.push(infoLinha)
            }
        });

        import('xlsx').then(xlsx => {
            const headers = {
                NCNJ: 'Processo',
                advogadoReverso_oab: 'Advogado Adverso',
                ecBase: 'Escritório de Base',
                status: 'Status ',
                fase: 'Fase',
                momento: 'Momento',
                custasProcessuais: '$ Custas Processuais',                
                status_pact: 'Status Pact',
                status_pre: 'Status pre',
                provisao: 'Provisão',
                risco_bruto: 'Risco Bruto',
                gatilho_bruto: 'Gatilho Bruto',
                status_negociacao: 'Status da Negociação',
                termometro: 'Termometro',
                data_protocolo: 'Data do Protocolo',
                data_homologacao: 'Data da Homologação',
                acordo_bruto: 'Acordo Bruto',
                acordo_liquido: 'Acordo Líquido',
                desagio: 'Deságio',
                honorario_pact: 'Honorário PACT',
            };

            productsFiltered.unshift(headers); // if custom header, then make sure first row of data is custom header 
            const worksheet = XLSX.utils.json_to_sheet(productsFiltered, { skipHeader: true });
            //  worksheet = xlsx.utils.json_to_sheet(productsFiltered);
            const workbook = { Sheets: { 'processos': worksheet }, SheetNames: ['processos'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Processos_');
        });
    }
    else {
        toast.warn(`Não possui registro para download`);
    }
};

const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            const date = new Date();
            let formatedDay = date.toLocaleDateString();
            module.default.saveAs(data, fileName + formatedDay + EXCEL_EXTENSION);
        }
    });
}


export const listaAprovacoes = () => {
    return[{
        id: '1', 
        cnj: '5000123-62.2020.5.08.0254', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '2 - Recursal',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },
    {
        id: '2', 
        cnj: '5000123-62.23024.5.08.0255', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '1 - Conhecimento',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '3', 
        cnj: '5000123-62.2020.5.08.02546', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '2 - Recursal',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.0,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '4', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '3 - Execução',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '5', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '2 - Recursal',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '6', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '3 - Execução',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '7', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '2 - Recursal',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '8', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '2 - Recursal',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '9', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '3 - Execução',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    },{
        id: '10', 
        cnj: '5000123-62.2020.5.08.0257', 
        grupoCliente: 'Grupo / Cliente', 
        fase: '1 - Conhecimento',  
        risco: 'Risco', 
        acordoLiquido: 215003.56, 
        acordoBruto: 285003.56, 
        inssParteRe: 102.5,
        irrf: 45645,
        gatilhoBruto: 412.2,
        desagio: 0.3001,
        honorariosPact:105.52,
        custo: 1045.56,
        honorarioPericiais: 10.5,
        formaPagamento: 'A vista',
        obrigacaoPagamento: '',
        checklistProcesso: '',
    }]
}

export const listaProcessos = () => {
    return [{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Projeto - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },{
        id: "0001354-232013.5.15.1445",
        NCNJ: "0001356-72.2013.5.15.0053",
        contrarioPrincipalNomeRazao: "",
        uf: "SP",
        cidade: "Campinas",
        advogadoReverso_oab: "Adv 5",
        advogado: "Adv 5",
        oab: "143.763-SP",
        vara: "Vara do Trabalho",
        nVara: "4",
        dtDistribuicao: "",
        ecBase: "Tozzini Freire Advogados",
        riscoDCliente: "208291.69",
        faixaRisco: "",
        valorCausa: "",
        tipoProcesso: "",
        tipoAcao: "",
        primeiraReclamada: "General Electric do Brasil",
        demaisReclamadas: "",
        status: "Encerrado",
        fase: "3 - Execução",
        momento: "30 Acordo - Cliente",
        dtMomento: "",
        dtAudiencia: "",
        dtEncerramento: "",
        motivoEncerramento: "",
        ticketMedio: "",
        valorAcordoCondenacao: "",
        formaPagamentoAcordo: "",
        quemPagouAcordo: "",
        momentoEmQueAcordoRealizado: "",
        pedidosDaInicial: "",
        funcaoCompilada: "",
        proprioTerceiro: "",
        ultimoSalario: "",
        dtAdmissao: "",
        dtDemissao: "",
        tempoCasa: "",
        sentenca: "",
        dtSentenca: "",
        nomeJuiz: "",
        houveReformaAcordaoRO: "",
        pedidosIncluidosAcordao: "",
        pedidosExcluidosAcordao: "",
        dtAcordao: "",
        acordaoReformouImprocedencia: "",
        rrAdmitido: "",
        seFoiAdmitidoQualMateria: "",
        houveReformaAcordaoRR: "",
        dtAcordao2: "",
        juros: "",
        hPericiais: "",
        honorariosAdvocaticios: "",
        custasProcessuais: "",
        estabilidade: "",
        bonus: "",
        equiparacaoSalarial: "",
        acumuloDeFuncao: "",
        dMoral: "",
        dMaterial: "",
        pensao: "",
        he: "",
        horasInItinere: "",
        sebreaviso: "",
        intrajornada: "",
        diferencasSalariais: "",
        adTransferencia: "",
        insalubridade: "",
        periculosidade: "",
        ticketMedioporPedido: "",
        garantia: "",
        possuiGarantia: "",
        garantiaMaiorouMenorqueRisco: "",
        contrarioPrincipalNomeRazao2: "",
        tipoGarantiaRecursalDepositoApolice: "",
        valorLiberado: "",
        revelia: "",
        reclamanteFoiOuvidaemAud: "",
        testemunhaReclamada: "",
        testemunhaReclamante: "",
        dtTransitoemJulgado: "",
        valorCalcApresentadoReclamada: "",
        valorCalcApresentadoReclamante: "",
        valorCalcApresentadoPerito: "",
        valorHomologado: "",
        dtCalcHomologado: "",
        deQuemfoiCalcHomologado: "",
        houveEmbargosExecucao: "",
        materiaEe: "",
        formaDeGarantia: "",
        houvePericia: "",
        nomePeritoPericulosidadeInsalubridade: "",
        nomePeritoAcidenteDoenca: "",
        periciaDeQue: "",
        resultadoInsalubridade: "",
        fundamentoInsalubridade: "",
        resultadoPericulosidade: "",
        agentePericulosidade: "",
        fundamentoPericulosidade: "",
        agenteInsalubre: "",
        resultadoDoenca: "",
        resultadoAcidente: "",
        dtLaudo: "",
        compliadofunHe: "",
        agenteInsalubre2: "",
        grauDaInsalubridade: "",
        compiladofunPericulosidade: "",
        compiladofunAcidDoenca: "",
        qualDoenca: "",
        incapacidade: "",
        reducaoFuncional: "",
        compiladoFundDanoMoral: "",
        empresa: "Empresa 1 - ACORDOS",
        tipo1: "",
        tipo2: "",
        tipo3: "",
        tipo4: "",
        tipo5: "",
        tipo6: "",
        tipo7: "",
        tipo8: "",
        tipo9: "",
        tipo10: "",
        baseCliente: "Empresa 1 - ACORDOS",
        cpf_reclamante: "",
        projeto: "Empresa 1 - ACORDOS",
        status_pact: "Encerrado",
        cnj_execucao_provisoria: null,
        momento_execucao_provisoria: null,
        data_cadastro_processo_base: "Sun Apr 14 2019 13:52:29 GMT-0300 (Horário Padrão de Brasília)",
        status_pre: "Acordo PACT",
        data_provisao: null,
        provisao: null,
        data_atualizacao_risco: "Mon Apr 29 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        risco_bruto: "",
        risco_liquido: "",
        risco_bruto_pact_net_atualizado: "208291.69",
        risco_liquido_pact_net_atualizado: "183899.09",
        depositos_recursais: "8960",
        faixa_risco: "De 100K a 300K",
        gatilho_bruto: "187462.52",
        status_negociacao: "Acordo Faturado",
        termometro: "...Especificar",
        data_primeiro_contato: "Thu Feb 21 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_ultimo_contato: "Wed May 22 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        proposta: "160000",
        ulitma_pretensao: undefined,
        data_acordo: "Fri Jun 07 2019 12:35:59 GMT-0300 (Horário Padrão de Brasília)",
        data_protocolo: "Wed Jun 12 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        data_homologacao: "Sun Jul 14 2019 21:00:00 GMT-0300 (Horário Padrão de Brasília)",
        acordo_bruto: "166785.26",
        acordo_liquido: "187462.51",
        status_minutas: "Acordo Faturado",
        inss_reclamada_pago: "19241.76",
        inss_reclamante_pago: "1435.49",
        ir: "0",
        honorarios_periciais: "0",
        economia: "0.01",
        desagio: "20829.18",
        honorario_pact: "2000",
        economia_net: "-1999.99",
        desagio_net: "18829.18",
        status_atividade: "",
        negociar: undefined,
        tribunal: "TRT 15 - Tribunal Regional do Trabalho da 15ª Região",
        dataStatusPre: "Fri Jun 07 2019 12:37:10 GMT-0300 (Horário Padrão de Brasília)",
        Observacao: "",
        descricaoSolicitacaoCopia: "",
        descricaoSolicitacaoCalculo: "",
        descricaoUltimaAcaoNegociar: "Ação encerrada automaticamente pelo sistema. Cobrar resposta.",
        statusPreSubTipo: "Concluído - Acordo",
        fluxoSaneamento: "Acordo",
        restricaoTipo: null,
      },]
}

export const filtroUsuario = [
    {value: 1, label: 'Ativo'},
    {value: 0, label: 'Inativo'},
]

export const tipoUsuario = [{ value: 1, label: 'Administrador Global'}, { value: 2, label: 'Cliente' }]

export const exportExcelCustom = (modeloHeader, data, nomeArquivo) => {
    
    import('xlsx').then(xlsx => {
        const headers = modeloHeader

        data.unshift(headers);
        const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });
        const workbook = { Sheets: { 'arquivos': worksheet }, SheetNames: ['arquivos'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, nomeArquivo+'_');
    });
}