import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Rotas from './routes'
import AuthProvider from './contexts/LegalInsights/auth'
import ImportacaoProvider from './contexts/importacao'
import CrudProvider from './contexts/crud'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { LicenseInfo } from '@mui/x-license-pro';
import LegalInsightsProvider from './contexts/LegalInsights/LegalInsightsCrudContext'

function App() {

  LicenseInfo.setLicenseKey(process.env.REACT_APP_KEY_MATERIAL);

  return (
    <AuthProvider>
      <LegalInsightsProvider>
        <ImportacaoProvider>
          <CrudProvider>          
            <BrowserRouter>
              <ToastContainer autoClose={3000} />
              <Rotas />
            </BrowserRouter>
          </CrudProvider>
        </ImportacaoProvider>
      </LegalInsightsProvider>
    </AuthProvider>
  );
}

export default App;
