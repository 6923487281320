
import React from 'react'
import './index.css'

import CustomCardModulos from '../../Components/LegalInsights/CustomCardModulos'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/LegalInsights/auth'
import { buscarIcon 
} from '../../utils/Compartilhados'
import { useNavigate } from 'react-router-dom'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'


const Modulos = () => {

    const { user , signOut } = useContext(AuthContext)
    const { setMenuSelecionado , listarModulos, modulos, setModuloSelecionado } = useContext(LegalInsightsCrudContext)
    const [modulosUsuario, setModulosUsuario] = useState([])
    const navegate = useNavigate()

    useEffect(()=>{
        if(user){
            listarModulos()
        }
    },[user])

    useEffect(()=>{
            modulos.filter( modulo =>{
                if(modulo.modulo === "seguros"){
                    modulo.acesso = user.perfil?.acessoModuloSeguros
                }

                if(modulo.modulo === "projetoAcordo"){
                    modulo.acesso = user.perfil?.acessoModuloProjetoAcordo
                }

                if(modulo.modulo === "gestaoDados"){
                    modulo.acesso = user.perfil?.acessoModuloPainelGestao
                }

                if(modulo.modulo === "depositoJudicial"){
                    modulo.acesso = user.perfil?.acessoModuloDepositoJudicial
                }
            })

        setModulosUsuario(modulos)
    },[modulos])

    const handleSubmit = async () => {        
        const postData = {
            credenciais: {
                emailmaster: process.env.REACT_APP_PORTAL_LEGALINSIGHTS_EMAIL,
                senhamaster: process.env.REACT_APP_PORTAL_LEGALINSIGHTS_PASSWORD,
                emailuser: user.email
            }
        };
          
        const base64Data = btoa(JSON.stringify(postData));
    
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = process.env.REACT_APP_PORTAL_LEGALINSIGHTS_POWERBI_EXTERNO;
    
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'data';
        input.value = base64Data;
        form.appendChild(input);
            
        const newWindow = window.open('', '_blank');    
        newWindow.document.title = 'Legal Insights'
        newWindow.document.body.appendChild(form);
        form.submit()
    };
    
    const navegateToModulo = async (item) => {        
        const { modulo, linkPortalExterno } = item
        let moduloAcesso = modulo
        
        if(!user.perfil?.acessoMenuStatusReports & modulo === 'projetoAcordo'){
            setMenuSelecionado('id-menu-aprovacoes')  
            moduloAcesso = 'projetoAcordoAprovacoes'
        }
        setModuloSelecionado(item.label)
        switch(moduloAcesso){
            case 'projetoAcordo':
                if(user.perfil?.acessoMenuStatusReports){
                    setMenuSelecionado('id-menu-status-report')    
                }                
                return navegate(`/${modulo}/statusReport`)
            case 'projetoAcordoAprovacoes':
                return navegate(`/${modulo}/aprovacoes`)
            case 'gestaoDados':
                return await handleSubmit()
            case 'seguros':
                return window.open(linkPortalExterno, '_blank')
            case 'higenizacao':
                return navegate(`/${modulo}`)
            case 'depositoJudicial':
                setMenuSelecionado('id-deposito-judicial-menu-report-substituicao')   
                return navegate(`/${modulo}/statusReport`)
        }
    }

    return(<div className='main-container-modulos'>
        <div className="modulos-header">
            <div>
                <span>{`Olá, ${user.nome}`}</span>
            </div>            
        </div>
        
        <div className="modulos-cards">
            {modulosUsuario?.map( (item, index) => {
                return <CustomCardModulos key={index} index={index}
                            iconLeft={item.acesso? buscarIcon(item.iconLeft) : buscarIcon(item.iconAcessoNegado) } 
                            iconRight={buscarIcon(item.iconRight)} 
                            titulo={item.label} 
                            btnLabel={item.acesso? 'Acessar' : 'Saiba mais'} 
                            moduloBloqueado={item.acesso} 
                            btnDisable={!item.acesso} 
                            onClick={()=> 
                                {
                                    if(!item.acesso){
                                        return window.open('http://wa.me/5511915576213','_blank')
                                    }

                                    if(item.acesso){
                                        navegateToModulo(item)
                                    }
                                }
                                
                            }
                            ordenacaoExibicaoModulo={item.index}
                        />
            })}
        </div>
        <div className="modulos-footer">
            <img src={buscarIcon('IconSistemaLILogo')} alt="Icon Sistema LI sair" onClick={() => signOut()}/>
        </div>
    </div>)
}

export default Modulos