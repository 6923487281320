import React, {useContext, useEffect, useState } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import ReactLoading from 'react-loading'
import { PowerBIEmbed } from 'powerbi-client-react'


import './index.css'
import { models } from 'powerbi-client'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { Dialog } from 'primereact/dialog'
import { IoNotificationsOutline } from "react-icons/io5";

const ProjetoAcordoStatusReport = () => {
    const { showMenuConfiguracoes, showMeuPerfil, setMenuSelecionado, loading, getPowerBIPortal, apiGetConfigPortal, statusReportsAcesso, filters, usuarioTemPowerBI } = useContext(LegalInsightsCrudContext)
    const { user } = useContext(AuthContext)
    const [powerBI, setPowerBI] = useState({})
    const [_filters, _setFilters] = useState([])
    const [usuarioTemStatusReports, setUsuarioTemStatusReports] = useState(false)

    useEffect(()=>{
        setMenuSelecionado("id-menu-status-report")
        getPowerBIPortal()
    },[])

   useEffect(()=>{ 
    if(apiGetConfigPortal){
        setPowerBI(apiGetConfigPortal) 
    }        
    },[apiGetConfigPortal])

    useEffect(()=>{
        _setFilters(filters)
    },[filters])

    useEffect(()=>{ setUsuarioTemStatusReports(!usuarioTemPowerBI) },[usuarioTemPowerBI])

    const showModalUsuarioSemStatusReports = () => { setUsuarioTemStatusReports(!usuarioTemStatusReports)}

    return(
        <div className="modulo-projeto-acordos-container">
            <Header />   

            <div className="projeto-acordo-container">
                <div className="projeto-acordo-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('projetoAcordo', user?.perfil)}/>
                </div>

                <div className="projeto-acordo-conteudo">
                    <div className={`main-container-conteudo-status${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`}>
                        {loading?  
                            <div className='loadProcessos-status-report'>
                                <ReactLoading type='spin' color='#4e1975' />
                            </div>
                            : statusReportsAcesso? 
                            <div className='loadProcessos-status-report'>
                                <PowerBIEmbed 
                                    embedConfig={{
                                        type: "report",
                                        id: powerBI?.CodigoReport,
                                        embedUrl: powerBI?.EmbeddedUrl,
                                        accessToken: powerBI?.Token,
                                        tokenType: models.TokenType.Aad,
                                        permissions: models.Permissions.All,                                        
                                        settings:{
                                            panes:{
                                                filters:{
                                                    visible: false,
                                                    expanded: false                                                    
                                                }
                                            },
                                            background: models.BackgroundType.Transparent,
                                            navContentPaneEnabled: false,
                                        },
                                        pageName: "Visão Geral",
                                        filters: _filters
                                    }}

                                    eventHandlers={
                                        new Map([
                                          ['loaded', function () { console.log('Report loaded'); }],
                                          ['rendered', function () { console.log('Report rendered'); }],
                                          ['error', function (event) { console.log(event.detail); }],
                                          ['pageChanged', async function (event) { 
                                                console.log(event.detail); 

                                                if(event.detail.newPage.displayName != powerBI?.TITULO){
                                                    const pages = await window.report.getPages();
                                                    const visaoGeralPage = pages.find(page => page.displayName.includes("Visão Geral"));
                                                    if (visaoGeralPage) {
                                                        visaoGeralPage.setActive();
                                                    }  else{
                                                        return 
                                                    }                                        
                                                }
                                        }]                                          
                                        ])
                                    }
                                    cssClassName='report-style-class'                                    
                                    
                                    getEmbeddedComponent={(embeddedReport) => {    
                                            window.report = embeddedReport;                                                                                
                                    }}
                                />
                            </div> : usuarioTemPowerBI === false? 
                                     <Dialog
                                     visible={usuarioTemStatusReports}
                                     style={{ width: '450px' }}
                                     header={<IoNotificationsOutline size={25} className='icon-sem-status-reports'/>}
                                     modal
                                     onHide={showModalUsuarioSemStatusReports}
                                   >
                                     <div className='sem-status-reports'>
                                        <div className='conteudo'>
                                            <p>Prezado usuário, informamos que seu</p>
                                            <p>painel está em processo de criação e</p>                                            
                                            <p>estará disponível neste local em até 5</p>
                                            <p>dias úteis</p>
                                            <br></br>
                                            <p>Em caso de dúvidas, não hesite em</p>
                                            <p>contatar-nos.</p>
                                        </div>
                                     </div>              
                                   </Dialog>
                            : <></>
                        }
                    </div>            
                </div>

                <div className="projeto-acordo-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default ProjetoAcordoStatusReport
