import React, {useContext, useEffect, useState } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import './index.css'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { AppBar, Box, Toolbar,  Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@mui/material'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { ActionButton, ActionButtonUpload } from '../../../../Components/Commons/ButtonMaterial'
import { ActionSelect } from '../../../../Components/Commons/SelectMaterial'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import { formartarData } from '../../../../utils'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ActionTextField } from '../../../../Components/Commons/TextFieldMaterial'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ProjetoAcordoArquivos = () => {
    const { 
            showMenuConfiguracoes, 
            showMeuPerfil, 
            setMenuSelecionado,
            salvarArquivoAws,
            consultarProjetosRLANET,
            projetosRLANET,
            donwloadAzureStorageBlod,
            arquivos,
            buscaTodosArquivos,
            columnVisibilityModel, alterColumnVisibility

    } = useContext(LegalInsightsCrudContext)    
    const { user } = useContext(AuthContext)

    const [_cliente, _setCliente] = useState()
    const [cliente, setCliente] = useState()
    const [baseCliente, setBaseCliente]= useState()
    const [tipoArquivo, setTipoArquivo]= useState()
    const [openDialog, setOpenDialog] = useState(false)
    const [fileName, setFileName] = useState('Nome do arquivo ( ex. Nova base 2024)')
    const [file, setFile] = useState()
    const [_arquivos, _setArquivos] = useState([])
    
    useEffect(()=>{
        setMenuSelecionado("id-menu-arquivos")
        consultarProjetosRLANET()
        buscaTodosArquivos()
        alterColumnVisibility({ id: false }) 
    },[])

    useEffect(()=>{
        setBaseCliente(projetosRLANET)
    },[projetosRLANET])


    useEffect(()=>{
        _setArquivos(arquivos)
    },[arquivos])

    const columns = [
        {
            field: "id",
            headerName: "id",
            width: 10,
            hide: columnVisibilityModel?.id
        },
        {
            field: "nomeArquivo",
            headerName: "Arquivo",
            width: 350,
        },
        {
            field: "usuario",
            headerName: "Usuário",
            width: 250,
        },
        {
            field: "baseCliente",
            headerName: "Base Cliente",
            width: 250,
        },
        {
            field: "tipoArquivo",
            headerName: "Tipo de arquivo",
            width: 250,
        },

        {
            field: "dataCriacao",
            headerName: "Data criação",
            width: 200,
            valueFormatter: ({ value }) => value && formartarData(value)
        },

        {
            field: "download",
            headerName: "Download",
            width: 100,
            renderCell: (event) => downloadArquivo(event)
        }
    
    ]

    const downloadArquivo = (event) =>{       
        const { accountName, containerName, blobName } = event.row.azureStorageBlod
        return(<div className='download-arquivos'>
           <CloudDownloadIcon onClick={ async () => await download(accountName, containerName, blobName )} />        
        </div>)        
    }

    const download = async (accountName, containerName, blobName) => {
        const linkDownload = await donwloadAzureStorageBlod(accountName, containerName, blobName)       
        const a = document.createElement('a');
        a.href = linkDownload;
        a.download = ''; 
        a.rel = 'noopener noreferrer';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const onCloseDialog = () => {
        setOpenDialog(!openDialog)
    }

    const onChangeFile = (event) =>{        
        setFileName(event.target.files[0].name)
        setFile(event.target.files[0])        
    }

    const onClickSendFile = async() =>{
        try {
            if(!file){
                return showToast('info','Por favor, selecione um arquivo primeiro!')
            }

            if(!cliente){
                return showToast('info','Por favor, informar um cliente!')
            }
            
            const payloadData = {
                nomeUsuario: user.nome,
                baseCliente: baseCliente?.filter( item => item.value === cliente.value)[0].label,
                tipoArquivo: tipoArquivo
            }
            await salvarArquivoAws(file, payloadData)                
            setFileName('Nome do arquivo ( ex. Nova base 2024)')
            buscaTodosArquivos()
            onCloseDialog()
            return showToast('success','Arquivo salvo com sucesso!')
        } catch (error) {
            console.log('Func: onClickSendFile | Erro ao salvar arquivo no aws')   
        }
    }

    const limparFiltro = () => {
        _setArquivos(arquivos)
        showToast('info','Dados atualizado com sucesso!')
    }

    const onInputChange = (event) =>{        
        setTipoArquivo(event.target.value)
    }

    return(
        <div className="modulo-projeto-acordos-container">
            <Header />   

            <div className="projeto-acordo-container">
                <div className="projeto-acordo-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('projetoAcordo', user?.perfil)}/>
                </div>

                <div className="projeto-acordo-conteudo">
                    <div className={`main-container-conteudo-status${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`}>
                        <Box sx={{ flexGrow: 1 }} className="box-header-arquivos">
                            <AppBar position="static" className='app-bar-header-arquivos' id="app-bar-header-arquivos">
                                <Toolbar variant="regular" className='toolbar-header-arquivos' id='toolbar-header-arquivos' >
                                    <div className='form-arquivo-select' id='form-arquivo-select'>
                                        <ActionSelect label={'Clientes'} value={_cliente} data={baseCliente} name={'cliente'} onChange={(event)=> 
                                            {
                                                _setCliente(event.target) 
                                                const clienteLabel = baseCliente.filter( item => item.value === event.target.value)[0].label
                                                const filterArquivos = arquivos.filter( item => item.baseCliente === clienteLabel )
                                                _setArquivos(filterArquivos)                                                
                                            }
                                        }/>
                                    </div>
                                    <div>
                                        <ActionButton onClick={limparFiltro} btnStyle={'btn_style_1'}>Limpar filtro</ActionButton>
                                        <ActionButton onClick={onCloseDialog} >Importar</ActionButton>
                                    </div>
                                   
                                </Toolbar>
                            </AppBar>                        
                        </Box >

                        <Box sx={{ height: '80vh', width: '100%' }}>
                            <DataGridCustom                                             
                                column={columns} 
                                rows={_arquivos} 
                                selection={false}                                                       
                            />
                        </Box>
                    </div>       

                    <Dialog 
                        open={openDialog} 
                        onClose={onCloseDialog} 
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '50vh', height: '50vh',  backgroundColor: '#eeeeee', borderRadius: '30px' } }}
                        id='dialog'
                    >                         
                        <DialogContent id='dialog-content'>
                            <DialogContentText id='dialog-content-text'>                                
                                <SystemUpdateAltIcon />
                                <DialogTitle id="dialog-title">Importar Processo
                                    <IconButton
                                        aria-label="close"
                                        onClick={onCloseDialog}
                                        sx={{
                                            position: 'absolute',
                                            right: 20,
                                            top: 20,                                            
                                            height: 25,
                                            width: 25,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <CloseIcon sx={{
                                            fontSize: '5px',                                            
                                            cursor: 'pointer',
                                            '&:hover':{
                                                color: '#ffffff',                                                
                                                backgroundColor:(theme) => theme.palette.grey[300],                                               
                                                borderRadius: '50%'
                                            }
                                        }}/>
                                    </IconButton>
                                </DialogTitle>                            
                            </DialogContentText>
                            <div>

                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    margin: 'auto',
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                 
                                    <ActionSelect label={'Base cliente'} value={cliente} data={baseCliente} name={'baseCliente'} onChange={(event)=>{ setCliente(event.target) }}/>
                                    <ActionTextField label={'Tipo de arquivo'}  onChange={onInputChange} value={tipoArquivo}/>
                            </Box>

                               
                            </div>
                            <div className='div-escolher-arquivo'>           
                                <ActionButtonUpload onChange={onChangeFile}>Escolher arquivo</ActionButtonUpload>
                                <input className='input-file-name' type="text" placeholder={fileName}/>                              
                            </div>    
                        </DialogContent>
                        <DialogActions>
                            <ActionButton onClick={onClickSendFile}>{'Enviar'}</ActionButton>                            
                        </DialogActions>
                    </Dialog> 
                </div>  
            </div>  
        </div>
    )
}

export default ProjetoAcordoArquivos